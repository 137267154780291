// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import '@reach/dialog/styles.css'
import 'rc-slider/assets/index.css'

import { ApolloProvider } from '@apollo/client'
import { defaultSeo } from '@kijiji/seo/configs/defaultSeo'
import { theme } from '@kijiji/theme'
import { GoogleTagManager } from '@next/third-parties/google'
import { OptimizelyProvider } from '@optimizely/react-sdk'
import { type NextPage } from 'next'
import { type AppProps } from 'next/app'
import localFont from 'next/font/local'
import { SessionProvider } from 'next-auth/react'
import { type SSRConfig, appWithTranslation, useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import React, { useEffect, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import { CookiePermissions } from '@/components/shared/cookie-permissions'
import { GlobalStyle } from '@/constants/theme'
import { getMachIdFromCookie } from '@/features/advertisement/utils/gptTargeting'
import { AuthenticationGuard } from '@/features/auth/components/authentication-guard'
import { type APOLLO_STATE_PROP_NAME, useApollo } from '@/lib/apollo/apolloClient'
import { RemoteConfigProvider } from '@/lib/firebase/RemoteConfigProvider'
import { type FirebaseConfig } from '@/lib/firebase/types'
import { GA_TRACKING_ID } from '@/lib/ga/trackingId'
import { OptimizelyClient } from '@/lib/optimizely/OptimizelyClient'
import { cleanDomainDuplicatedCookies } from '@/utils/cookies/cleanDomainDuplicatedCookies'
import { getUserDevice } from '@/utils/getUserDevice'
import { getAbsoluteUrl } from '@/utils/url'

import nextI18nConfig from '../../next-i18next.config'

export type NextApplicationPage<P = object, IP = P> = NextPage<P, IP> & {
  protected?: boolean
}

export interface BasePageProps {
  /** user session */
  session?: Session | null
  /** machine uuid identifier for tracking and optimizely */
  machId?: string | null // TODO: This should be required
  /** request user agent */
  userAgent?: string
  /** apollo application state */
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject
  /** translations  */
  _nextI18Next?: SSRConfig['_nextI18Next']
  /** catch all */
  [key: string]: unknown
  /** Firebase remote config containing toggles, configs, envs etc */
  firebaseConfig?: FirebaseConfig
}

interface Props extends AppProps<BasePageProps> {
  Component: NextApplicationPage
}

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const ReactDOM = require('react-dom')
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000)
}

const optimizelyInstance = OptimizelyClient.getClient()

/**
 * next/font automatically determines the best fallback font
 * and uses the size-adjust property to minimize layout shift
 * the fontLoader properties must be string literals
 * https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#local-fonts
 * */
const larsseitFonts = localFont({
  src: [
    {
      path: '../../public/next-assets/fonts/Larsseit.woff2',
      weight: '400',
      style: 'normal',
    },
    { path: '../../public/next-assets/fonts/LarsseitMedium.woff2', weight: '500', style: 'normal' },
  ],
})

const App = ({ Component, pageProps }: Props) => {
  const apolloClient = useApollo(pageProps)

  const { t } = useTranslation(['common', 'home'])

  const defaultSeoValues = useMemo(
    () =>
      defaultSeo({
        titleLong: `${t('common:seo.meta.title_long')}`,
        titleTemplate: `%s | ${t('common:meta.subtitle')} `,
        title: t('common:seo.meta.title'),
        description: t('common:seo.meta.description'),
        image: getAbsoluteUrl('/next-assets/images/fb_desktop.jpg'),
      }),
    [t]
  )

  useEffect(() => {
    cleanDomainDuplicatedCookies()
  }, [])

  return (
    <>
      {/* For the portals to be in scope, we need to inject the next/font into the head */}
      <style jsx global>{`
        html {
          font-family: ${larsseitFonts.style.fontFamily};
          font-display: swap;
        }
      `}</style>
      {/* The session prop needs to be passed down from the page's props so the useSession hook loads instant instead of loading first */}
      <SessionProvider session={pageProps.session} refetchOnWindowFocus={true}>
        <ApolloProvider client={apolloClient}>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <DefaultSeo {...defaultSeoValues} />
            <RemoteConfigProvider config={pageProps.firebaseConfig}>
              <OptimizelyProvider
                optimizely={optimizelyInstance}
                timeout={500}
                user={{
                  id: pageProps.machId ?? getMachIdFromCookie(),
                  attributes: { isMobile: getUserDevice().isPhoneOrTablet },
                }}
              >
                <GoogleTagManager gtmId={GA_TRACKING_ID} />
                {Component.protected ? (
                  <AuthenticationGuard>
                    <Component {...pageProps} />
                  </AuthenticationGuard>
                ) : (
                  <Component {...pageProps} />
                )}

                <CookiePermissions />
              </OptimizelyProvider>
            </RemoteConfigProvider>
          </ThemeProvider>
        </ApolloProvider>
      </SessionProvider>
    </>
  )
}

/**
 * The typing for `nextI18nConfig` coming from "node_modules/next-i18next/dist/types/types.d.ts"
 * does not match the typing coming directly from i18n and even Next's own typing.
 *
 * See: https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
 *    Specifically `localeDetection`
 *
 * See: https://www.i18next.com/principles/fallback#key-not-found
 *    Specifically `fallbackLng`
 *
 * We can look into manually overriding these type definitions later but we need to get support
 * for the other options that i18n explicitly accepts in its config.
 */
export default appWithTranslation(App, nextI18nConfig)
