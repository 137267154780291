export type PSAKeys = 'appDownloadPSA' | 'siteMaintenancePSA_dec17th'

export const PSA_BANNER_KEYS: { [x: string]: PSAKeys } = {
  APP_DOWNLOAD: 'appDownloadPSA',
  SITE_MAINTENANCE: 'siteMaintenancePSA_dec17th',
}

type PSABannerContent = {
  /**
   * Default value - shouldn't be removed before adding any new campaigns
   */
  [x in PSAKeys]: {
    /**
     * Defines if the PSA banner has highlighted text
     * This title will show in Yellow
     * @default false
     */
    hasHighlightedTitle?: boolean
    /**
     * Translation key to access the psa banner translation
     */
    intlKey: string
    /**
     * PSA banner tracking key
     */
    psaKey: string
    /**
     * Start Date of the campaign
     */
    startDate?: Date
    /**
     * End Date of the campaign
     */
    endDate?: Date
  }
}

/**
 * Make sure to clean up old marketing campaigns that won't be used anymore when adding new ones
 * */
export const PSA_BANNER: PSABannerContent = {
  appDownloadPSA: {
    intlKey: 'app_download',
    psaKey: PSA_BANNER_KEYS.APP_DOWNLOAD,
  },
  siteMaintenancePSA_dec17th: {
    intlKey: 'site_maintenance',
    psaKey: PSA_BANNER_KEYS.SITE_MAINTENANCE,
    /**
     * We currently don't handle timezones
     */
    startDate: new Date('2024-012-12T09:00:00'),
    endDate: new Date('2024-12-17T05:00:00'),
  },
}
