/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Helper for passing objects from SSR
 * Remove all undefined values
 */
export const removeUndefinedFromObject = (obj: Record<string, any>) => {
  // Create a new object to store the result
  const result = {} as any

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      // Directly assign arrays without any modification
      result[key] = obj[key]
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively remove undefined fields if the value is an object
      const cleanedNestedObject = removeUndefinedFromObject(obj[key])
      // Only add the key-value pair if the cleaned nested object is not empty
      if (Object.keys(cleanedNestedObject).length !== 0) {
        result[key] = cleanedNestedObject
      }
    } else if (obj[key] !== undefined) {
      // Add the key-value pair to the result object if the value is not undefined
      result[key] = obj[key]
    }
  }

  // Return the result object with no undefined fields
  return result
}
