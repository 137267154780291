export type boxShadowKeys = keyof typeof boxShadow

export const boxShadow = {
  'shadow-1': '0 0.1rem 0.2rem rgba(62, 65, 83, 0.1)',
  'shadow-2': '0 0.06rem 0.31rem rgba(52, 55, 70, 0.1)',
  'shadow-3': '0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.2)',
  'shadow-3-noshift': '0 0 0.4rem rgba(0, 0, 0, 0.2)',
  'shadow-4': '0.2rem 0.2rem 0.4rem rgba(62, 65, 83, 0.1)',
  'shadow-5': '0 0 0.8rem rgba(38, 129, 219, 0.5)',
}
