import {
  type GetCategoryPathQuery,
  type GetCategoryPathQueryVariables,
  GetCategoryPathDocument,
} from '@kijiji/generated/graphql-types'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { getLocale } from '@/domain/locale'
import { initializeApollo } from '@/lib/apollo/apolloClient'
import { type DataLayerCategory, type DataLayerLocation } from '@/lib/ga/types/dataLayer'

/**
 * Returns the category object of the the datalayer
 */
export const getDatalayerCategory = async ({
  categoryId,
  locationId,
}: {
  categoryId?: number
  locationId: number
}): Promise<DataLayerCategory> => {
  const apolloClient = initializeApollo()
  const { cookieLocale } = getLocale()

  /**
   * If no category is passes, it should default to "Classifieds"
   * If the category is 0, it should take the category name "All Categories"
   *
   * This will prevent fetching category path when L0 is the current category
   */
  if (!categoryId) {
    return { c: { c: { id: categoryId ?? ALL_CATEGORIES_ID_NUM, n: 'Classifieds' } } }
  }

  const { data } = await apolloClient.query<GetCategoryPathQuery, GetCategoryPathQueryVariables>({
    query: GetCategoryPathDocument,
    variables: { categoryId, locationId, locale: cookieLocale },
    fetchPolicy: 'cache-first',
  })

  const { categoryPaths } = data.category ?? {}
  const current = { id: categoryId, n: data.category?.localizedName ?? undefined }

  /** Return minimal possible value if there were issues trying to fetch the category information */
  if (!categoryPaths?.length) return { c: { c: current } }

  const c1 = categoryPaths[0]
  const c2 = categoryPaths[1]
  const c3 = categoryPaths[2]

  const getName = (localizedName?: string | null) => localizedName ?? undefined

  const optionalPath: Pick<DataLayerLocation['l'], 'l1' | 'l2' | 'l3'> = {}

  if (c1) optionalPath.l1 = { id: c1.id, n: getName(c1.localizedName) }
  if (c2) optionalPath.l2 = { id: c2.id, n: getName(c2.localizedName) }
  if (c3) optionalPath.l3 = { id: c3.id, n: getName(c3.localizedName) }

  return { c: { c: current, ...optionalPath } }
}
