import { isSSG } from '@/utils/isSSG'
import { isServer } from '@/utils/isSSR'

const useAnvilDirectly = process.env.NEXT_PUBLIC_DEV_SSL === 'true'

export const getAnvilUrl = () => {
  const isStaticSiteGeneration = isSSG()
  if (isServer() && !isStaticSiteGeneration)
    return `${process.env.NEXT_PUBLIC_SERVER_ANVIL_URL}/api`

  if (useAnvilDirectly || isStaticSiteGeneration)
    return `${process.env.NEXT_PUBLIC_CLIENT_ANVIL_URL}/api`

  return `${process.env.NEXT_PUBLIC_WEBAPP_URL}/anvil/api`
}
