import { CookieRegistry } from '@/constants/cookieRegistry'
import { type DataLayerBase } from '@/lib/ga/types/dataLayer'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

/**
 * Returns the device object of the the datalayer
 */
export const getDatalayerDevice = (): Pick<DataLayerBase, 'd'> => {
  const userAgent = window?.navigator.userAgent
  const machineId = getCookieByKey(document.cookie, CookieRegistry.MACHINE_ID)

  return { d: { ck: machineId, ua: userAgent } }
}
