import { type Locale } from '@kijiji/generated/graphql-types'

import { getLocale } from '@/domain/locale'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { type DataLayerLocation } from '@/lib/ga/types/dataLayer'

/**
 * Returns the location object of the the datalayer
 */
export const getDatalayerLocation = (locationId: number): DataLayerLocation => {
  const { apiLocale } = getLocale()
  const locationPath = getLocationPath(locationId)

  const current = locationPath[locationPath.length - 1]
  /** Index 0 is always "Canada" */
  const l0 = locationPath[0]
  const l1 = locationPath[1]
  const l2 = locationPath[2]
  const l3 = locationPath[3]

  const getName = (name: Locale) => name[apiLocale] ?? ''

  const optionalPath: Pick<DataLayerLocation['l'], 'l0' | 'l1' | 'l2' | 'l3'> = {}

  if (l0) optionalPath.l0 = { id: l0.id, n: getName(l0.name) }
  if (l1) optionalPath.l1 = { id: l1.id, n: getName(l1.name) }
  if (l2) optionalPath.l2 = { id: l2.id, n: getName(l2.name) }
  if (l3) optionalPath.l3 = { id: l3.id, n: getName(l3.name) }

  return {
    l: { c: { id: current.id, n: getName(current.name) }, ...optionalPath },
  }
}
