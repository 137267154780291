import { useContext } from 'react'

import { ParamsContext } from '@/lib/firebase/RemoteConfigProvider'
import {
  type RemoteConfigKeys,
  type RemoteEnvKeys,
  type RemoteExperimentKeys,
  type RemoteToggleKeys,
} from '@/lib/firebase/types'

export const useToggle = (key: RemoteToggleKeys) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useToggle must be used within a RemoteConfigProvider')
  }
  return context.getToggle(key)
}

export const useConfig = (key: RemoteConfigKeys) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useConfig must be used within a RemoteConfigProvider')
  }
  return context.getConfig(key)
}

export const useEnv = (key: RemoteEnvKeys) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useEnv must be used within a RemoteConfigProvider')
  }
  return context.getEnv(key)
}

export const useExperiment = (key: RemoteExperimentKeys) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useContext must be used within a RemoteConfigProvider')
  }
  return context.getExperiment(key)
}

export const useExperimentsDataLayer = () => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useExperiementsDataLayer must be used within a RemoteConfigProvider')
  }
  const experiments = context.getAllExperiments()

  if (!experiments) return ''

  const experimentsDataLayerString = Object.values(experiments).reduce(
    (acc, exp) => (exp.key && exp.group !== -1 ? `${acc}${exp.key}=${exp.group};` : acc),
    ''
  )

  return experimentsDataLayerString
}
