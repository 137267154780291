import merge from 'deepmerge'
import { useRouter } from 'next/router'
import { type Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { hasSystemMsgQueryParam } from '@/domain/hasSystemMsgQueryParam'
import { hasUserFeedbackQueryParam } from '@/domain/hasUserFeedbackQueryParam'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { usePrevious } from '@/hooks/usePrevious'
import { useExperimentsDataLayer } from '@/lib/firebase/hooks'
import { type DATALAYER_SRP_VIEW_TYPE, type GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import {
  type DataLayerBase,
  type DataLayerCategory,
  type DataLayerLocation,
} from '@/lib/ga/types/dataLayer'
import { clearDataLayer, pushToDataLayer } from '@/lib/ga/utils/gatDatalayerActions'
import { type GetBaseDatalayerProps, getBaseDatalayer } from '@/lib/ga/utils/getBaseDatalayer'
import { trackPageView } from '@/lib/ga/utils/trackPageView'
import { removeUndefinedFromObject } from '@/utils/removeUndefinedFromObj'

export type UseTrackingProps = Omit<
  GetBaseDatalayerProps,
  'session' | 'apolloClient' | 'locationId'
> & {
  locationId?: number
  /**
   * Additional data to be added to the dataLayer
   */
  pageData?: Record<string, unknown>
  /**
   * Page type for Google Analytics.
   * Refer to https://wiki.es.ecg.tools/pages/viewpage.action?pageId=98439770
   */
  pageType: GA_PAGE_TYPE
  /**
   * Determines whether or not we should clear the dataLayer.
   * We may not want to clear the dataLayer in some instances.
   * For example, when manually calling the `reportPageview` function (ex. when changing data related to a filter).
   */
  shouldClearDataLayer?: boolean
  /** (SRP Only) View type of Search page */
  searchView?: DATALAYER_SRP_VIEW_TYPE
  /**
   * (SRP Only) The tracking should only be re-triggered when the path is returned by the query
   * If we use "asPath" from router we will re-trigger the datalayer before the response is returned and updated
   * Using useRouter "asPath" This will cause to track the previous data instead of the new one
   * */
  searchPath?: string

  /**
   * Extra Page Load Tracking
   * Any events that should be triggered on page load after the datalayer is sent
   * (i.e. Search Impressions)
   */
  extraPageLoadingTracking?: (
    baseDatalayer: DataLayerBase & DataLayerCategory & DataLayerLocation
  ) => void
}

export const useTracking = ({
  categoryId,
  extraPageLoadingTracking,
  locationId: propsLocationId,
  pageData = {},
  pageType,
  searchPath,
  searchView,
}: UseTrackingProps) => {
  const { location } = useGetLocation()
  const { status, data: session } = useSession()
  const { asPath, query } = useRouter()
  const experiments = useExperimentsDataLayer()

  /** It should automatically update the datalayer if the user changes */
  const [prevSessionId, setPrevSessionId] = useState<Session['user']['sub'] | undefined>()
  const didSessionChange = session?.user.sub !== prevSessionId

  const previousPath = usePrevious(searchPath)
  const isFirstRender = useRef<boolean>(true)
  const locationId = propsLocationId ?? location.id

  const triggerTracking = useCallback(async () => {
    clearDataLayer()

    const baseData = await getBaseDatalayer({
      categoryId,
      locationId,
      pageType,
      searchView,
      session,
      experiments,
    })

    const fullData = removeUndefinedFromObject(merge(baseData, pageData))

    pushToDataLayer(fullData, 0)
    trackPageView(asPath)

    extraPageLoadingTracking?.(baseData)
  }, [
    asPath,
    categoryId,
    extraPageLoadingTracking,
    locationId,
    pageData,
    pageType,
    searchView,
    session,
    experiments,
  ])

  useEffect(() => {
    if (
      (!hasSystemMsgQueryParam(query) &&
        !hasUserFeedbackQueryParam(query) &&
        status !== 'loading') ||
      didSessionChange
    ) {
      /**
       * Is this the initial page load, or the first time we've
       * visited this URL (in the case of shallow navigation)
       */
      if (
        isFirstRender.current ||
        didSessionChange ||
        (!!searchPath && previousPath !== searchPath)
      ) {
        isFirstRender.current = false
        setPrevSessionId(session?.user.sub)
        triggerTracking()
      }
    }
  }, [
    didSessionChange,
    previousPath,
    query,
    searchPath,
    session?.user.sub,
    status,
    triggerTracking,
  ])
}
