import { sendGTMEvent } from '@next/third-parties/google'

import DataLayerTaskQueue from '@/lib/task-queue/DataLayerTaskQueue'

/**
 * Separating out the priority from isInitialBuild since there
 * are multiple priority dataLayer events that need to be
 * inserted *prior* to other events, namely the page_meta
 * and VirtualPageView events.
 *
 * This way, events pushed to the data layer using this method,
 * prior to the TaskQueue being marked as initialized may be
 * flagged at priority 0 to get them sorted to the front of
 * the queue.
 */
const TaskQueue = new DataLayerTaskQueue()

export const pushToDataLayer = (
  data: Record<string, unknown> | (() => void),
  priority = 1,
  isInitialBuild = false
) => {
  if (typeof window === 'undefined') return

  /**
   * requestIdleCallback is not available in all browsers
   * but we can use setTimeout as a fallback
   * https://caniuse.com/?search=requestidlecallback
   *
   * setTimeout with 0ms delay adds the callback to the event loop
   * which unblocks the main thread and allows the browser to render
   */
  const pushData =
    typeof requestIdleCallback !== 'undefined'
      ? () => requestIdleCallback(() => sendGTMEvent(data))
      : () => setTimeout(() => sendGTMEvent(data), 0)

  if (!TaskQueue.isInitialized) {
    TaskQueue.addTask(pushData, priority)

    if (isInitialBuild) {
      TaskQueue.processQueue()
      TaskQueue.setIsInitialized(true)
    }
  } else {
    pushData()
  }
}

export const clearDataLayer = () => {
  /**
   * We don't want to put this in the TaskQueue because the clear should always
   * be processed before the page_meta and VirtualPageView events are inserted,
   * or it will wipe out the metadata required for the subsequent events to be
   * useful, and when inserted into the TaskQueue, the reset, which clears the
   * DataLayer winds up getting inserted after those events.
   *
   * This technique for resetting the dataLayer is documented here:
   * https://developers.google.com/tag-platform/devguides/datalayer#reset
   */
  sendGTMEvent(function () {
    // @ts-expect-error `this` is used by the Google dataLayer scripts, and does not refer to a class that we have control over
    this.reset()
  })
  TaskQueue.setIsInitialized(false)
}
