import { ThemeProps } from '@kijiji/theme'
import styled, { css } from 'styled-components'

import {
  KEYBOARD_ONLY_OUTLINE,
  TRANSITION_STYLE,
} from '@/ui/constants/commonStyles'
import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { ButtonProps, ButtonSizes } from './Button'
import {
  buttonColorVariation,
  buttonScale,
  styleFromButtonColorVariation,
} from './utils'

type ButtonBaseProps = RequiredPickMap<
  ButtonProps,
  | 'isFullWidth'
  | 'isLoading'
  | 'isSelected'
  | 'size'
  | 'shape'
  | 'variant'
  | 'gap'
> & {
  $as?: string
}

export type SharedButtonStyleProps = RequiredPickMap<
  ButtonProps,
  'isFullWidth' | 'size' | 'shape' | 'variant' | 'gap'
> & {
  theme: ThemeProps
}

const getResponsiveButtonSize = (value: ButtonSizes, theme: ThemeProps) => {
  const buttonText =
    value === 'compact'
      ? theme.typography.body.medium
      : theme.typography.body[value]
  return `${styleFromTypography(buttonText)}
  min-height: ${buttonScale[value]?.height || buttonScale.medium.height};
  padding: ${buttonScale[value]?.padding || buttonScale.medium.padding};
`
}

const getButtonShape = (value: string, theme: ThemeProps) => `
  border-radius: ${value === 'pill' ? theme.borderRadius.rounded : theme.borderRadius.small};
`

export const sharedButtonStyle = ({
  theme,
  $isFullWidth = false,
  $size = 'medium',
  $shape = 'rectangular',
  $variant = 'primary',
  $gap,
}: SharedButtonStyleProps) => css`
  ${KEYBOARD_ONLY_OUTLINE}
  ${TRANSITION_STYLE}
  ${applyResponsiveStyle($size, theme, getResponsiveButtonSize)}
  ${styleFromButtonColorVariation(
    buttonColorVariation(theme)[$variant].default
  )}
  ${getButtonShape($shape, theme)}
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-weight: ${theme.fontWeight.medium};
  justify-content: center;
  margin: 0;
  width: ${$isFullWidth ? '100%' : 'fit-content'};
  ${$gap ? `gap: ${$gap};` : ''}
  &:hover {
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].hover
    )}
    text-decoration: none;
  }
  &:active,
  &:focus-visible {
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].focus
    )}
  }
  &:disabled {
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].disabled
    )}
    cursor: not-allowed;
  }
`

export const ButtonBase = styled.button<ButtonBaseProps>`
  ${({
    theme,
    $isFullWidth,
    $size,
    $shape,
    $variant,
    $isLoading,
    $isSelected,
    $as,
    $gap,
  }) => css`
    ${sharedButtonStyle({ theme, $isFullWidth, $size, $shape, $variant, $gap })}

    ${$as === 'a' ? `text-decoration: none;` : ``}

    ${$isSelected
      ? `
    ${styleFromButtonColorVariation(
      buttonColorVariation(theme)[$variant].focus
    )}
    `
      : ``}

    ${$isLoading
      ? `
      ${styleFromButtonColorVariation(
        buttonColorVariation(theme)[$variant].loading
      )}
      cursor: ${$isLoading ? 'wait' : 'not-allowed'}`
      : ``}
  `}
`
