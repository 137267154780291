import TaskQueue from '@/lib/task-queue/TaskQueue'

class DataLayerTaskQueue extends TaskQueue {
  isInitialized: boolean

  constructor() {
    super()
    this.isInitialized = false
  }

  setIsInitialized(initialized: boolean) {
    this.isInitialized = initialized
  }
}

export default DataLayerTaskQueue
