import { type ReactNode, createContext, useMemo } from 'react'

import {
  type Config,
  type Env,
  type Experiment,
  type FirebaseConfig,
  type RemoteConfigKeys,
  type RemoteEnvKeys,
  type RemoteExperimentKeys,
  type RemoteToggleKeys,
  type Toggle,
} from '@/lib/firebase/types'

interface ParamsContextValue {
  getToggle: (key: RemoteToggleKeys) => Toggle | undefined
  getConfig: (
    key: RemoteConfigKeys
  ) => (Omit<Config, 'data'> & { data: { [key: string]: unknown } }) | undefined
  getEnv: (key: RemoteEnvKeys) => Env | undefined
  getExperiment: (key: RemoteExperimentKeys) => Experiment | undefined
  getAllExperiments: () => Record<string, Experiment> | undefined
}

interface RemoteConfigProviderProps {
  config?: FirebaseConfig
  children: ReactNode
}
export const ParamsContext = createContext<ParamsContextValue | undefined>(undefined)

export const RemoteConfigProvider: React.FC<RemoteConfigProviderProps> = ({ config, children }) => {
  const context = useMemo(() => {
    const { toggles = {}, configs = {}, envs = {}, experiments = {} } = config ?? {}

    const getEnv = (key: RemoteEnvKeys) => envs[key]
    const getExperiment = (key: RemoteExperimentKeys) => experiments[key]
    const getAllExperiments = () => experiments
    const getToggle = (key: RemoteToggleKeys) => toggles[key]
    const getConfig = (key: RemoteConfigKeys) => {
      const { data: serializedData, ...rest } = configs[key]
      try {
        const data =
          typeof serializedData === 'string' ? JSON.parse(serializedData) : serializedData
        return data ? { data, ...rest } : undefined
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        console.warn(`Remote config ${key} contained invalid JSON data: ${e}`)
        return undefined
      }
    }

    return {
      getToggle,
      getConfig,
      getEnv,
      getExperiment,
      getAllExperiments,
    }
  }, [config])

  return <ParamsContext.Provider value={context}>{children}</ParamsContext.Provider>
}
