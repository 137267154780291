import { pushToDataLayer } from '@/lib/ga/utils/gatDatalayerActions'

/**
 * Tracks a page view by pushing it to the data layer
 *
 * @param url - The URL of the page being viewed
 */
export const trackPageView = (url: string) => {
  pushToDataLayer({ event: 'VirtualPageView', virtualPageUrl: url }, 0, true)
}
