import { type NextRouter } from 'next/router'

import { USER_FEEDBACK } from '@/constants/systemMessages'

/**
 * Returns a boolean value indicating if the current query object has a userFeedback parameter
 *
 * We currently have a logic to re-render the page based on query parameters that will be removed from the URL
 * (The logic should be replaced later with a shallow routing to avoid re-renders)
 *
 * As the current implementation can cause tracking issues, this domain helps us find out if there is any query parameters on the URL
 * This will mean the page will still re-render and we might skip tracking before those values are cleared from the URL
 */
export const hasUserFeedbackQueryParam = (query?: NextRouter['query']): boolean => {
  const hasParameter = query ? Object.keys(query).find((item) => !!USER_FEEDBACK[item]) : undefined

  return !!hasParameter
}
