import { getLocale } from '@/domain/locale'
import { type DATALAYER_SRP_VIEW_TYPE, type GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { PLATFORM_VERSION } from '@/lib/ga/constants/ga'
import { type DataLayerBase } from '@/lib/ga/types/dataLayer'

/**
 * Returns the page object of the the datalayer
 * It includes the platform version, language and page type (i.e. VIP)
 *
 * @param pageType - Name of the current page
 * @param searchView - (SRP Only) View type of Search page
 * @returns
 */
export const getDatalayerPage = (
  pageType: GA_PAGE_TYPE,
  searchView?: DATALAYER_SRP_VIEW_TYPE
): Pick<DataLayerBase, 'p'> => {
  const { apiLocale } = getLocale()

  return { p: { pl: PLATFORM_VERSION, lng: apiLocale, t: pageType, vt: searchView } }
}
