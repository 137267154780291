import { ThemeProps } from '@kijiji/theme/src'
import styled from 'styled-components'

import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { BodyTextProps, BodyTextSize } from './BodyText'

type BodyTextBaseProps = RequiredPickMap<
  BodyTextProps,
  'color' | 'size' | 'textAlign' | 'weight'
>

const getResponsiveSize = (value: BodyTextSize, theme: ThemeProps) =>
  styleFromTypography(theme.typography.body[value])

export const BodyTextBase = styled.p<BodyTextBaseProps>(
  ({ $weight, $color, $size, $textAlign, theme }) => `
  ${applyResponsiveStyle($size, theme, getResponsiveSize)}

  color: ${$color};
  font-family: inherit;
  font-weight: ${theme.fontWeight[$weight]};
  text-align: ${$textAlign};
`
)
